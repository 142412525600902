<template>
  <div class="d-flex d-space-between">
    <v-autocomplete
     v-bind="$attrs"
      v-model="suggestion"
      :items="suggestionList"
      :loading="isAppLoading"
      :search-input.sync="search"
      hide-no-data
      hide-selected
      no-filter
      clearable
      outlined
      :item-text="suggestionText"
      :item-value="suggestionValue"
      :label="label"
      :prepend-icon="icon"
      :return-object="returnObject"
      v-on:keyup.enter="selectedSuggestion"
      @click:clear="clearSearch()"
      ref="autocomplete"
    ></v-autocomplete>
    <v-btn
      v-if="showAddButton"
      @click="selectedSuggestion"
      icon
      outlined
      class="mt-2 ml-5"
      ><v-icon>mdi-plus</v-icon></v-btn
    >
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    value: {
      type: [String, Object, Number],
      required: false
    },
    label: {
      type: String,
      required: false,
      default: "Enter your search term"
    },
    returnObject: {
      type: Boolean,
      required: false,
      default: true
    },
    icon: {
      type: String,
      required: false,
      default: "mdi-account-search"
    },
    suggestionText: {
      type: String,
      required: true,
      default: "label"
    },
    suggestionValue: {
      type: String,
      required: true,
      default: "value"
    },
    showAddButton: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: () => {
    return {
      suggestion: null,
      suggestionList: [],
      search: null
    };
  },
  computed: {
    ...mapGetters("navigation", ["isAppLoading"]),
    ...mapGetters("auth", ["currentUser"])
  },
  watch: {
    // Suggestion changed
    suggestion(newRespondent) {
      this.selectedSuggestion(newRespondent);
    },
    search(query) {
      if (!query) {
        return;
      }

      let selected = false;
      if (this.suggestion) {
        selected = query == this.suggestion[this.suggestionText];
      }

      let exist = false;
      if (this.suggestionList && this.suggestionList.length > 0) {
        this.suggestionList.some(r => r[this.suggestionText] == query);
      }
      if (!selected && !exist) {
        if (this.isLoading) return;

        this.queryServer(query);
      }
    }
  },
  methods: {
    ...mapActions([
      "clearMessage",
      "addMessage",
      "addErrorMessage",
      "addSuccessMessage"
    ]),
    clearSearch(){
      this.$emit('input', null); 
      this.$emit("selected", null)
    },
    selectedSuggestion(newRespondent) {
      if (newRespondent && newRespondent[this.suggestionValue]) {
        let selectedSuggestion = null;

        if (this.suggestionList && this.suggestionList.length > 0) {
          selectedSuggestion = this.suggestionList.find(
            t => t[this.suggestionValue] == newRespondent[this.suggestionValue]
          );

          this.suggestion = selectedSuggestion;
        } else {
          this.suggestion = null;
        }

        this.$emit("input", this.suggestion);
        this.$emit("selected", this.suggestion);
      }

      this.$refs.autocomplete.isMenuActive = false;
    },
    queryServer(query) {
      this.isLoading = true;
      this.$emit("search", query, result => {
        this.suggestionList = result ? result : [];
        this.isLoading = false;
      });
    }
  }
};
</script>

<style></style>
